export function initAfterPay(selector = '[data-afterpay-container]', container_selector = '[data-product-form]', options = {}) {
    const afterpayContainer = document.querySelector(selector);

    if (!afterpayContainer) {
        return;
    }

    const afterpayGlobalContainer =
        afterpayContainer.closest(container_selector);
    if (!afterpayGlobalContainer) {
        return;
    }

    const locale = [
        `${window?.Shopify?.locale?.toUpperCase() || ''}`,
        `${window?.Shopify?.country_code || ''}`
    ]
        .filter((part) => Boolean(part))
        .join('_');

    if (
        [
            !window?.Afterpay?.locale[locale],
            !window?.Afterpay?.currency[window?.Shopify?.currency?.active]
        ]?.includes(true)
    ) {
        return;
    }

    function updateAfterPay(afterpay_price) {
        afterpayContainer.innerHTML = '';

        const afterpay_placements = document.querySelectorAll(`${selector} ~ afterpay-placement`);
        if (!afterpay_placements) {
            return;
        }

        if (afterpay_placements?.length) {
            afterpay_placements.forEach((el) => el.remove());
        }

        window?.Afterpay?.createPlacements({
            ...{
                targetSelector: selector,
                attributes: {
                    ...{
                        locale: window?.Afterpay?.locale[locale],
                        currency: window?.Afterpay?.currency[window?.Shopify?.currency?.active],
                        amount: afterpay_price,
                        size: window?.Afterpay?.size?.SM,
                        badgeTheme: window?.Afterpay?.theme?.badge?.WHITE_ON_BLACK
                    },
                    ...(options || {}),
                }
            },
        });
    }

    window.updateAfterPay = updateAfterPay;

    let product_price = Number(afterpayContainer?.dataset?.price);

    const selected_tab = afterpayGlobalContainer?.querySelector('.product-form__price--selected');
    if (selected_tab) {
        product_price = selected_tab?.dataset?.afterpayPrice;
    }

    updateAfterPay(product_price);

    const memberTabs = afterpayGlobalContainer?.querySelectorAll('[data-afterpay-price]');

    if (!memberTabs) {
        return;
    }

    if (!memberTabs?.length) {
        return;
    }

    const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
            if (!['attributes', 'childList']?.includes(mutation?.type)) {
                continue;
            }

            if (
                !mutation?.target?.className?.includes('product-form__price--selected')
            ) {
                continue;
            }

            const afterpay_price = mutation?.target?.dataset?.afterpayPrice;
            if (!afterpay_price) {
                continue;
            }

            updateAfterPay(afterpay_price);
        }
    });

    const config = {
        attributes: true,
        childList: true,
        subtree: true
    };

    memberTabs.forEach((tab) => {
        observer.observe(tab, config);
    });
}

window.initAfterPay = initAfterPay;

window.addEventListener('Afterpay.ready', () => {
    initAfterPay();
});
